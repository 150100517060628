<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import vue2Dropzone from 'vue2-dropzone'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Loading,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      options: [],
      form: {
        discount: '',
        description: '',
        link: '',
        image: '',
        type: 'all',
      },

      optionsStatus: [
        { value: true, text: 'show' },
        { value: false, text: 'hide' },
      ],

      isEdit: false,
      ismanuallyadd: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 100,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      optionsType: [
        { value: 'all', text: 'ALL' },
        { value: 'member', text: 'MEMBER' },
      ],
    }
  },
  validations: {
    form: {
      discount: {
        required,
      },
      description: {},
      link: {
        required,
      },
    },
  },
  mounted() {
    this.initialUpdateItem()
  },
  methods: {
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async formSubmit() {
      this.doAjax()

      let body = {
        discount: this.form.discount,
        description: this.form.description,
        link: this.form.link,
        type: this.form.type,
      }

      const data = new FormData()

      if (!this.ismanuallyadd) {
        let img = this.form.image
        data.append('image', img)
      }

      data.append('data', JSON.stringify(body))

      const queryId = this.$route.query.id
      const request = !this.isEdit
        ? axios.post('/api/discount', data)
        : axios.patch(`/api/discount/${queryId}`, data)
      await request
        .then(async (result) => {
          this.onCancel()
          this.$toast.success('Saved successfully')
          this.$router.push('/discount')
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },
    async initialUpdateItem() {
      const id = this.$route.query.id
      if (!id) return

      await axios.get(`api/discount/${id}`).then((res) => {
        this.form = { ...this.form, ...res.data }

        this.isEdit = true
        var file = { size: 123, name: 'Icon', type: 'image/png' }
        if (this.form.image) {
          var url = `${this.baseImageUrl}/${this.form.image}`
          this.$refs.myVueDropzone.manuallyAddFile(file, url)
        }
      })
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Content</h4>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Code</label>
                      <b-form-input
                        id="discount"
                        v-model="$v.form.discount.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('discount')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Link</label>
                      <b-form-input
                        id="link"
                        v-model="$v.form.link.$model"
                        placeholder="Enter link..."
                        name="title"
                        type="text"
                        :state="validateState('link')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Description</label>
                      <b-form-input
                        id="description"
                        v-model="$v.form.description.$model"
                        placeholder="Enter description..."
                        name="title"
                        type="text"
                        :state="validateState('description')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <label>
                      Image
                      <small class="color-text-small"
                        >Width 340 x Height 195. Just only one.</small
                      >
                    </label>
                    <vue-dropzone
                      id="form-image"
                      ref="myVueDropzone"
                      accepted-files="image/*"
                      :options="dropzoneOptions"
                      aria-describedby="input-3-live-feedback"
                      @vdropzone-file-added="vfileAdded"
                      @vdropzone-file-added-manually="vfilemanuallyAdded"
                    ></vue-dropzone>
                    <b-form-group label-cols-lg="0">
                      <label>Show Discount Type</label>
                      <b-form-select
                        style="margin-left:10px"
                        v-model="form.type"
                        :options="optionsType"
                      ></b-form-select>
                    </b-form-group>
                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                    ></loading>
                    <!-- :is-full-page="fullPage" -->

                    <b-form-group class="mt-5">
                      <router-link to="/discount">
                        <button
                          type="button"
                          class="btn btn-danger float-right ml-2"
                          >Cancel</button
                        >
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
